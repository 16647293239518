.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.container.about-page,
.container.contact-page,
.container.portfolio-page {
  h1 {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #65dc98;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 30px;
    left: 10px;
  }

  p {
    font-size: 14px;
    color: #ffff;
    font-family: arial;
    font-weight: 300;
    min-width: fit-content;
    animation: pulse 1s;

    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }

    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  a {
    color: #65dc98;
    &:visited {
      color: #65dc98;
    }
    &:hover {
      color: #65dc98;
    }
    &:active {
      color: #65dc98;
    }
  }
}
