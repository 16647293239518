.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    max-height: 90%;
  }

  .laptop {
    transform: translateY(30%) translateX(-20%);
    width: 30%;
    height: 100%;
    position: absolute;
    right: 0;

    .lt-gif {
      height: auto;
      width: 100%;
      animation: fadeIn 2.3s 3.1s backwards;
    }
  }

  h1 {
    color: #fff;
    font-size: 83px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    font-family: sans-serif;
    letter-spacing: 2px;
    animation: fadeIn 2.3s 3.1s backwards;
  }

  .flat-button {
    color: #65dc98;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 2px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #65dc98;
    border-radius: 4px;
    margin-top: 25px;
    margin-right: 20px;
    float: left;
    animation: fadeIn 3s 3.8s backwards;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    white-space: nowrap;

    &:hover {
      transform: translateY(-2px);
      background: #65dc98;
      color: #333;
    }
  }
}
